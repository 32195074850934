/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    passport: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 5a3 3 0 100 6 3 3 0 000-6M6 8a2 2 0 114 0 2 2 0 01-4 0m-.5 4a.5.5 0 000 1h5a.5.5 0 000-1z"/><path pid="1" d="M3.232 1.776A1.5 1.5 0 002 3.252v10.95c0 .445.191.838.49 1.11.367.422.908.688 1.51.688h8a2 2 0 002-2V4a2 2 0 00-1-1.732v-.47A1.5 1.5 0 0011.232.321l-8 1.454zM4 3h8a1 1 0 011 1v10a1 1 0 01-1 1H4a1 1 0 01-1-1V4a1 1 0 011-1"/>',
    },
});
